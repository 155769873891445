import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Flex } from 'rebass/styled-components'
import { theme } from '../common/theme'

const StyledShare = styled(Flex)`
  border: 1px solid ${theme.colors.baseGrayLight};
  color: ${theme.colors.baseGray};
  border-radius: 99rem;
  width: 41px;
  height: 41px;
  text-align: center;
  justify-content: center;
  align-items: center;
  line-height: 1;
  transition: all ${theme.speed.default};
  cursor: pointer;
  :hover {
    color: ${theme.colors.baseGrayDark};
    border: 1px solid ${theme.colors.baseGrayDark};
  }
`

const Share = ({ name, children, ...rest }) => {
  return (
    <StyledShare mb={[0, 0, 2]} {...rest}>
      <i className={`icon icon-fw icon-${name}`} />
    </StyledShare>
  )
}

Share.propTypes = {
  name: PropTypes.string,
}

Share.defaultProps = {
  name: '',
}

export default Share
