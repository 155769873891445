import React from 'react'
import ContentWrapper from './ContentWrapper'
import { Row, Column, Container } from '@roar/components'
import ShareWrapper from './ShareWrapper'
import { graphql } from 'gatsby'

const BlogContentWrapper = (props) => {
  return (
    <Container pb={[4, 10]} pt={[4, 10]} className="container">
      <Row className="row" flexDirection={['column', 'column', 'row']}>
        <Column width={[1, 1, 2 / 12]} order={[1, 1, 0]}>
          <div style={{ position: 'sticky', top: 100 }}>
            <ShareWrapper {...props.shareProps} />
          </div>
        </Column>
        <Column width={[1, 1, 8 / 12]} flex="0 1 auto" order={[0, 0, 1]}>
          <ContentWrapper {...props} />
        </Column>
      </Row>
    </Container>
  )
}

export default BlogContentWrapper

export const blog = graphql`
  fragment PostArchive on ContentfulPosts {
    __typename
    contentful_id
    id
    name
    slug
    date(formatString: "MMMM D, YYYY")
    category {
      name
      slug
      headline
    }
    postType {
      name
      headline
    }
    year: date(formatString: "YYYY")
    month: date(formatString: "MM")
    image: featuredImage {
      gatsbyImageData(layout: CONSTRAINED, width: 960, quality: 90)
      file {
        url
      }
    }
    imageLogo: logo {
      gatsbyImageData(layout: CONSTRAINED, width: 960, quality: 90)
      file {
        url
      }
    }
    hotspot {
      focalPoint {
        x: xp
        y: yp
      }
    }
    description {
      childMarkdownRemark {
        excerpt
      }
    }
  }
  fragment Post on ContentfulPosts {
    __typename
    id
    contentful_id
    name
    slug
    date(formatString: "MMMM D, YYYY")
    image: featuredImage {
      title
      gatsbyImageData(layout: FULL_WIDTH)
    }
    video {
      ...Video
    }
    content {
      raw
      references {
        __typename
        ... on ContentfulAsset {
          contentful_id
          title
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          caption: description
        }
        ... on ContentfulImage {
          id
          contentful_id
          externalLink
          caption
          altText
          loading
          style {
            animation {
              shouldAnimate
            }
            wrapper {
              pb
              pl
              pr
              pt
            }
            alignItems
            bg
            justifyContent
            p
            pl
            pr
            pt
            pbString
            pb
            mb
            mt
          }
          image {
            title
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            caption: description
          }
        }
        ... on ContentfulLayoutLeftRight {
          id
          contentful_id
          name
          ...LayoutLeftRight
        }
        ... on ContentfulLinks {
          ...LinkFields
        }
        ... on ContentfulVideo {
          contentful_id
          ...Video
        }
        ... on ContentfulDestinations {
          contentful_id
          slug
        }
        ... on ContentfulLayoutGrid {
          contentful_id
          ...Grid
        }
        ... on ContentfulPosts {
          contentful_id
          slug
          date
          year: date(formatString: "YYYY")
          month: date(formatString: "MM")
          postType {
            name
          }
          category {
            name
          }
        }
      }
    }
    description {
      childMarkdownRemark {
        excerpt
      }
    }
    category {
      name
      slug
      headline
    }
    postType {
      name
      headline
    }
    imageDisplayOptions
    relatedBlogPosts {
      slug
      category {
        id
        name
        slug
        headline
      }
      postType {
        id
        name
        name
        headline
      }
      date(formatString: "MMMM D, YYYY")
      year: date(formatString: "YYYY")
      month: date(formatString: "MM")
      image: featuredImage {
        gatsbyImageData(layout: CONSTRAINED, width: 960, quality: 90)
      }
      imageLogo: logo {
        gatsbyImageData(layout: CONSTRAINED, width: 960, quality: 90)
      }
      name
      description {
        childMarkdownRemark {
          excerpt
        }
      }
    }
  }
`
