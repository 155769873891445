import React from 'react'
import { Flex } from 'rebass/styled-components'
import { FacebookShareButton, LinkedinShareButton, PinterestShareButton, TwitterShareButton } from 'react-share'
import { Row, Column } from '@roar/components'
import Share from '@roar/components/src/components/Share/Share'

const ShareWrapper = ({ url, description, image, title, altText }) => {
  if (!url) return null

  return (
    <Row justifyContent={['flex-end']}>
      <Column width={1}>
        <Flex
          flexDirection={['row', 'row', 'column']}
          justifyContent={['space-around', 'space-around', 'flex-end']}
          alignItems={['center']}
          width="100%"
        >
          <FacebookShareButton url={url} quote={description} image={image}>
            <Share name="facebook" />
          </FacebookShareButton>

          <TwitterShareButton url={url} title={title} image={image}>
            <Share name="twitter" />
          </TwitterShareButton>

          <LinkedinShareButton url={url} title={title} image={image}>
            <Share name="linkedin" />
          </LinkedinShareButton>

          <PinterestShareButton url={url} description={title} media={image}>
            <Share name="pinterest" />
          </PinterestShareButton>
        </Flex>
      </Column>
    </Row>
  )
}

export default ShareWrapper
