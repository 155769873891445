import React from 'react'
import RichTextRenderer from '../components/RichTextRenderer'
import { Content } from '@roar/components'
import { Box } from 'rebass/styled-components'
import { mapper } from '../common/utils'
import { formatImage } from './ImageWrapper'

const CONTENT_MAP = {
  content: ['content'],
}

const IMAGE_MAP = {
  image: formatImage,
}

const ContentWrapper = (props) => {
  const { content } = mapper(props, CONTENT_MAP)
  const { image } = mapper(props, IMAGE_MAP)

  return (
    content && (
      <Content>
        {image && (
          <Box
            width={[0.4, 0.5]}
            sx={{
              float: 'right',
              margin: '0px 0px 4px 16px',
            }}
            {...image}
          />
        )}

        <RichTextRenderer content={content} />
      </Content>
    )
  )
}

export default ContentWrapper
